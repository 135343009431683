// old
import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Learn from "../components/learn"

const hardCtaSection = () => (
  <div className="flex flex-col gap-[10px]">
    <div className="flex items-center gap-[32px]">
      <div style={{ maxWidth: `190px` }}>
        <Link to="/learn-iphone">
          <StaticImage src="../images/app_store.png" alt="App Store" />
        </Link>
      </div>
      <div style={{ maxWidth: `190px` }}>
        <Link to="/learn-android">
          <StaticImage src="../images/play_store.png" alt="Play Store" />
        </Link>
      </div>
    </div>
    <p className="text-[#475467] text-[16px] italic">
      Download the app, it's free
    </p>
  </div>
)

const LearnPage = () => {
  return <Learn ctaSection={hardCtaSection()} />
}

export default LearnPage
